import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from 'lib/components/Button';
import TextField from 'lib/components/TextField';
import PasswordField from 'lib/components/PasswordField';
import Text from 'lib/components/Text';
import Card from 'lib/containers/Card';
import Flex from 'lib/containers/Flex';
import { Container, Col, Row } from 'lib/containers/Grid';
import DisplayInit from 'lib/layouts/DisplayInit';
import ReCAPTCHA from 'react-google-recaptcha';
import PATH_URL from 'router/path';
import cookie from 'react-cookies';
import { Formik } from 'formik';
import { authLogin, authResetChange } from 'services/auth';
import history from 'router/history';
import { useDispatch } from 'react-redux';
import ButtonLanguage from 'lib/components/Button/ButtonLanguage';
import { useTranslation } from 'react-i18next';
import { schema } from './schema';
import imageBg from './assets/bg.jpg';
import { encryptPassword } from 'util/encryptDecrypt';

const Login = () => {
  const [load, setload] = useState(false);
  const [acceptReset, setAcceptReset] = useState(true);
  const [recaptcha, setRecaptcha] = useState(undefined);
  const [isShowloading, setIsShowLoading] = useState(false);
  const [isShowExpiredText, setIsShowExpiredText] = useState(false);
  const DELAY = 100;
  const isAuthenticated = cookie.load('token');
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  let captcha;

  useEffect(() => {
    dispatch(authResetChange());
    if (isAuthenticated) {
      history.push(PATH_URL.DASHBOARD);
    }
    setTimeout(() => {
      setload(true);
    }, DELAY);
  }, []);

  const actionChangeSubmitLogin = async value => {
    const params = {
      email: value.email,
      recaptcha_token: recaptcha,
      password: encryptPassword(value.password)
    };
    if (acceptReset) {
      setIsShowExpiredText(true);
    } else {
      dispatch(authLogin(params, setIsShowLoading, i18n.language, captcha, setAcceptReset));
    }
  };

  const actionChangeRecaptchaKey = (value, setFieldValue) => {
    if (value) {
      setAcceptReset(false);
      setRecaptcha(value);
      setFieldValue('recaptcha_token', value);
      setIsShowExpiredText(false);
    }
  };

  const setCaptchaRef = ref => {
    if (ref) {
      return (captcha = ref);
    }
  };

  const actionExpiredCaptcha = () => {
    captcha.reset();
    setAcceptReset(true);
    setRecaptcha(undefined);
    setIsShowExpiredText(true);
  };

  return (
    <DisplayInit bg={imageBg}>
      <Container>
        <Row>
          <Col md={5}>
            {' '}
            <Formik
              initialValues={{ email: '', password: '', recaptcha_token: '' }}
              validationSchema={schema(t)}
              onSubmit={value => actionChangeSubmitLogin(value)}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue
              }) => (
                <div className="flex items-center min-h-screen">
                  <Card className="p-32">
                    <ButtonLanguage classNotActive={false} reloadTrueFalse={true} />
                    <form onSubmit={handleSubmit}>
                      <TextField
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        alert={errors.email}
                        showAlert={errors.email && touched.email}
                        className={'mb-10'}
                        placeholder="Email"
                        prefix="envelope"
                      />

                      <PasswordField
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={!load}
                        name="password"
                        alert={errors.password}
                        showAlert={errors.password && touched.password}
                        className={'mb-10'}
                        placeholder={t('common:input.password')}
                      />
                      <Flex justify="end">
                        <Link
                          to={PATH_URL.FORGOT_PASSWORD}
                          className="block mb-16 w-3/1 font-bold text-right text-azul"
                        >
                          {t('common:label.forgot_password')}?
                        </Link>
                      </Flex>
                      {load && (
                        <ReCAPTCHA
                          className="w-full"
                          theme="light"
                          ref={r => setCaptchaRef(r)}
                          hl={i18n.language}
                          //sitekey={'6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'}
                          sitekey={process.env.REACT_APP_KEY_CAPTCHA}
                          onExpired={actionExpiredCaptcha}
                          onChange={value => actionChangeRecaptchaKey(value, setFieldValue)}
                        />
                      )}
                      {(isShowExpiredText || errors.recaptcha_token) && (
                        <Text className="text-danger text-center text-sm">
                          {t('common:global.catpcha_must_be_checked')}
                        </Text>
                      )}
                      {/* {auth?.authFail && (
                        <div className="w-full mt-15 text-center text-white bg-red-500">
                          {auth?.authFail}
                        </div>
                      )} */}
                      <div className="flex justify-end mt-24">
                        {isShowloading ? (
                          <button type="button" className="w-full m-1 bg-rose-600" disabled>
                            <svg className="w-5 h-5 mr-3 animate-spin" viewBox="0 0 24 24" />
                            {t('common:label.process')}
                          </button>
                        ) : (
                          load && (
                            <Button rounded type="submit" size="large" className="w-full">
                              {t('common:button.signin')}
                            </Button>
                          )
                        )}
                      </div>
                    </form>{' '}
                  </Card>
                </div>
              )}
            </Formik>{' '}
          </Col>

          <Col md={7} className="flex items-center justify-end min-h-screen">
            <Flex justify="end">
              <Text tag="span" variant="title" className="text-white md:w-9/12">
                {t('common:login.label')}
              </Text>
            </Flex>
          </Col>
        </Row>
      </Container>
    </DisplayInit>
  );
};

export default Login;
