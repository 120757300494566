import React, { useEffect, useState } from 'react';
import { Route, useLocation } from 'react-router-dom';
import cookie from 'react-cookies';
import usePrevious from 'hook/usePrevious';
import { FallbackLoading } from 'lib/components/Loading';
import { apiPostWithToken } from 'services/api';
import { PATH_ADMIN } from 'services/path/admin';

const useVerifyRequest = () => {
  const location = useLocation();
  const [isLoading, setLoading] = useState(false);
  const previousPath = usePrevious(location.pathname);

  useEffect(() => {
    setLoading(true);

    apiPostWithToken(PATH_ADMIN.VERIFY)
      .then(({ data }) => {
        const { sid, expired_at } = data.data;

        cookie.save('token', sid, {
          path: '/',
          sameSite: 'strict',
          expires: new Date(expired_at),
          secure: process.env.NODE_ENV === 'production'
        });
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  }, [location.pathname]);

  return { isLoading: isLoading || previousPath !== location.pathname };
};

const VerifyRoute = ({ component: Component, ...rest }) => {
  const { isLoading } = useVerifyRequest();

  return (
    <Route
      {...rest}
      render={props => {
        if (isLoading) {
          return <FallbackLoading />;
        }

        return <Component {...props} />;
      }}
    />
  );
};

export default VerifyRoute;
