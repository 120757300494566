import React from 'react';
import { Link } from 'react-router-dom';
import Button from 'lib/components/Button';
import Text from 'lib/components/Text';
import Card from 'lib/containers/Card';
import Flex from 'lib/containers/Flex';
import { Container, Col, Row } from 'lib/containers/Grid';
import DisplayInit from 'lib/layouts/DisplayInit';
import PATH_URL from 'router/path';
import imageBg from './assets/bg.jpg';
import imageCharacter from './assets/character.png';
import { useTranslation } from 'react-i18next';

const ResetSuccess = () => {
  const { t } = useTranslation();
  return (
    <DisplayInit bg={imageBg}>
      <Container>
        <Row>
          <Col md={5} className="flex flex-col items-center justify-center min-h-screen">
            <img
              src="/logo/moraconnect-wordmark.svg"
              alt="logo"
              className="w-full px-24 mt-30 mb-30"
            />

            <Card className="p-48">
              <img src={imageCharacter} alt="Please Login" className="mb-20" />

              <Button tag={Link} to={PATH_URL.HOME} size="large" className="w-full">
                {t('common:button.signin')}
              </Button>
            </Card>

            <Text className="text-center mt-20">
              &copy; {new Date().getFullYear()} {t('common:label.copryight')}
            </Text>
          </Col>

          <Col md={7} className="flex items-center justify-end min-h-screen">
            <Flex justify="end" direction="col" className="md:w-9/12">
              <Text tag="h1" variant="title" className="mb-16">
                {t('common:label.safe')}
              </Text>

              <Text>{t('common:settings.reset_password_sucsess_desc')}</Text>
            </Flex>
          </Col>
        </Row>
      </Container>
    </DisplayInit>
  );
};

export default ResetSuccess;
